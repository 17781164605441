import React from 'react';
import { Row, Col } from 'reactstrap';
import Aux from '../../hoc/Aux/Aux';
import MainMenu from '../Navigation/MainMenu/MainMenu';
import KeyVisual from '../UI/ResponsiveImageSet/ResponsiveImageSet';
import KeyVisualXs from '../../assets/images/keyvisual-xs-rgb.webp';
import KeyVisualLg from '../../assets/images/keyvisual-lg-rgb.webp';
import Countdown from './Countdown/Countdown';
import PageNotFoundHeader from "./PageNotFoundHeader";


const header = (props) => (
    <Aux>
        <Row className="sticky-top">
            <Countdown votingPhases={props.votingPhases}/>
        </Row>
        <Row>
            <Col className="p-0">
                <MainMenu primaryNavigation={props.primaryNavigation} primaryNavigationCTA={props.primaryNavigationCTA} />
            </Col>
        </Row>
        <Row>
            <Col className="px-0">
                {props.pageNotFound ? <PageNotFoundHeader/> : <KeyVisual imageXs={KeyVisualXs} imageLg={KeyVisualLg} />}
            </Col>
        </Row>
    </Aux>
);

export default header;
