import React, {useEffect, useState} from 'react';
import Aux from '../../../hoc/Aux/Aux';

import {Col, Input, Label, FormFeedback, FormText, UncontrolledTooltip} from 'reactstrap';

import './FormInput.scss';

function FormInput (props) {
    let inputElement;
    const [currentTextContent, setCurrentTextContent] = useState('');
    const [helperFieldId, setHelperFieldId] = useState('');

    useEffect(() => {
        if (props.id) {
            setHelperFieldId(`${props.id}-helperText`);
        }
        else {
            setHelperFieldId(crypto.randomUUID());
        }
    }, [props.id])

    switch (props.elementType) {
        case ('input'):
            inputElement = (
                <Input
                    // Todo: Refactor elementConfig spread. There is too much unneeded props present (i.e. columns)
                    id={props.tooltip ? props.name : null}
                    {...props.elementConfig}
                    invalid={!!(props.invalid && props.touched && props.shouldValidate)}
                    value={props.value}
                    aria-describedby={helperFieldId}
                    onChange={onChange}
                    valid={!(props.invalid && props.shouldValidate)}/>
            );
            break;

        case ('textarea'):
            inputElement = (
                <Input
                    type="textarea"
                    placeholder={props.elementConfig.placeholder}
                    invalid={!!(props.invalid && props.touched && props.shouldValidate)}
                    value={props.value}
                    aria-describedby={helperFieldId}
                    onChange={onChange}
                    rows="8"/>
            );
            break;

        case ('select'):
            inputElement = (
                <Input
                    type="select"
                    autoComplete={props.elementConfig.autoComplete}
                    value={props.value}
                    onChange={props.changed}
                    aria-describedby={helperFieldId}
                    disabled={props.disabled}>

                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value} disabled={option.disabled} selected={option.selected}>{option.displayValue}</option>
                    ))}

                </Input>
            );
            break;

        case ('file'):
            if (props.elementConfig.multiple) {
                inputElement = (
                    <Input
                        type="file"
                        value={props.value}
                        name={props.name}
                        onChange={props.changed}
                        aria-describedby={helperFieldId}
                        multiple/>
                );
            } else {
                inputElement = (
                    <Input
                        type="file"
                        value={props.value}
                        name={props.name}
                        aria-describedby={helperFieldId}
                        onChange={props.changed}/>
                );
            }
            break;

        case ('checkbox'):
            inputElement = (
                <Input
                    type="checkbox"
                    value={props.value}
                    name={props.name}
                    id={props.name}
                    aria-describedby={helperFieldId}
                    invalid={!!(props.invalid && props.touched && props.shouldValidate)}
                    onChange={props.changed}/>
            );
            break;

        default:
            inputElement = (
                <Input
                    // Todo: Refactor elementConfig spread. There is too much unneeded props present (i.e. columns)
                    {...props.elementConfig}
                    aria-describedby={helperFieldId}
                    value={props.value}
                    onChange={onChange}/>
            );
    }

    function onChange(event) {
        setCurrentTextContent(event.target.value);
        props.changed(event);
    }

    let inputGroup;
    const tooltip = props.tooltip ? <UncontrolledTooltip placement="bottom" trigger="focus" target={props.name} className="w-100" dangerouslySetInnerHTML={createMarkup(props.tooltip)}/> : null;

    let lengthIndicator;
    if (props.maxLenght) {
        lengthIndicator = <>
            <small className={['form-text', 'form-input-length', (currentTextContent.length > props.maxLenght ? 'text-danger' : '')].join(' ')}>{currentTextContent.length} / {props.maxLenght} Zeichen</small>
        </>;
    }
    else {
        lengthIndicator = <small className="form-input-length-placeholder">&#8288;</small>;
    }

    function createMarkup() {
        return {__html: props.elementConfig.label};
    }

    let helperText;

    if (props.invalid && props.touched){
        helperText = <FormFeedback className="form-error-message" style={{display: 'inline-block'}} id={helperFieldId}>{props.errorMessage}</FormFeedback>
    }
    else if (!props.invalid && props.touched) {
        helperText = <small className="form-text text-success" id={helperFieldId}>{props.validMessage}</small>
    }
    else {
        helperText = <FormText id={helperFieldId}>{props.helperText}</FormText>
    }

    if (props.elementType === 'checkbox') {
        inputGroup = (
            <Col className="mb-3 form-check ms-3" md={props.elementConfig.columns}>
                {inputElement}
                <Label check for={props.name} dangerouslySetInnerHTML={createMarkup()} />
                {helperText}
            </Col>
        )
    } else {
        inputGroup = (
            <Col className={(props.wrapperCSSClass || 'mb-3')} md={props.elementConfig.columns}>
                <div className="form-text-input">
                    <Label>{props.elementConfig.label}</Label>
                    {inputElement}
                    {helperText}
                    {lengthIndicator}
                    {props.formText ? <FormText>{props.formText}</FormText> : null}
                    {tooltip}
                </div>
            </Col>
        )
    }

    return (
        <Aux>
            {inputGroup}
        </Aux>
    );
}

export default FormInput;
